import React from 'react';
import styled from "styled-components";
import { UIContext } from "./UIContext";
import H from "./Headline";
import Lightbulblogo from "../assets/icons/lightbulb.svg";
import Twitterlogo from "../assets/icons/twitter-logo-dark.svg";

const StyledSection = styled.section`
display: grid;
grid-template-columns: auto 1fr;
grid-template-rows: auto 1fr auto;
align-items: start;
padding: 2rem;
column-gap: 1rem;
background-color: rgba(0,0,0,0.05);
border: 1px solid rgba(0,0,0,0.3);
margin: 3rem 0rem 3rem 0rem;
:hover .infobox_twittershare {
    visibility: visible;
} 
.infobox_icon{
    grid-column-start: 1;
    grid-column-end: 1;
    filter: opacity(30%);
}
.infobox_headline {
    grid-column-start: 2;
    grid-column-end: 2;
    justify-self: stretch;
    margin: 0;
    ::after {
        /* content: "..."; */
        content: ${props => props.dots == "true" ? `"..."` : `""`};
    }
}

.infobox_twittershare {
    grid-column: 2/2;
    justify-self: end;
    visibility: hidden;
}

p.infobox_headline {
    padding: 0;
    line-height: inherit;
    font-size: calc(18px + 1.5vmin);
    text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
}

.infobox_text {
    margin-top: 2rem;
    grid-column-start: 2;
    grid-column-end: 2;
}

@media (max-width: 700px) {
    margin: 3rem 1rem 3rem 1rem;
    column-gap: 0.5rem;
   
}

`

// component settings
// headline="Once you log in for the first time"
// h="false" or "h1", "h2 ...
// type="info" TODO more
// dots="true" or "false"

const InfoBox = ({ type, headline, children, h, dots, tweetcopy }, location) => {


    const renderIcon = (type = "info") => {
        if (type == "info") {
            return (<img src={Lightbulblogo} alt={`tip`} height="42px" className="infobox_icon" />)
        } else if (type == "summary") {
            return ("asa")
        } else return
    }

    const renderHeadline = (h = "h3", headline) => {
        if (h == "false") {
            return (<p className="infobox_headline"><b>{headline}</b></p>)
        } else {
            return (<H className="infobox_headline" as={h}>{headline}</H>)
        }
    }

    const renderTwitterLink = (loc, copy, children) => {
        return `https://twitter.com/intent/tweet?text=${copy} - via ${loc}`
    }



    return (
        <UIContext.Consumer>
            {uicontext => (
                <a href={renderTwitterLink(uicontext.location.href, tweetcopy)} target="_blank" noopener>
                    <StyledSection className="infobox" dots={dots} type={type}>


                        {renderIcon(type)}
                        {/* <H className="infobox_headline" as={h}>{headline}</H> */}
                        {renderHeadline(h, headline)}

                        {type == "info" ? <div className="infobox_text">{children}</div> : ""}

                        {/* {headline.includes("line") ? "yes" : "no"} */}
                        <img src={Twitterlogo} alt={`share on twitter`} height="24px" className="infobox_twittershare" />
                    </StyledSection>
                </a>
            )}
        </UIContext.Consumer>
    );
};

export default InfoBox;