import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Hjem: "/",
  "Analytics Consulting": "/no/analyse-rådgivning",
  "GTM Opplæring": "/no/google-tag-manager-oppsett"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-setup"
);


const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Trenger jeg Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ja, fordi nettstedet ditt sannsynligvis ønsker å kjøre Google Analytics og andre tredjeparts skriptkoder. Å sette opp alt det er mye enklere og raskere med Google Tag Manager. I tillegg <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>laster nettstedet ditt litt raskere</a> også."
        }
      },
      {
      "@type": "Question",
      "name": "Når bør jeg bruke Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Allerede hvis du bare vil kjøre Google Analytics bør du bruke Google Tag Manager. Å sette opp <a href='https://bluerivermountains.com/no/hendelsessporing'>hendelsessporing</a> og andre konfigurasjoner er mye raskere og enklere med GTM. I tillegg finnes det mange veiledninger og guider på nettet som forklarer hvordan du f.eks. <a href='http://bluerivermountains.com/no/google-analytics-oppsett'>setter opp Google Analytics</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Hvordan bruker jeg Google Tag Manager med Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ikke legg Google Analytics-koden i kildekoden på nettstedet ditt. Legg bare til Google Tag Manager-koden og implementer og <a href='https://bluerivermountains.com/no/google-analytics-oppsett'>sett opp Google Analytics</a> gjennom Google Tag Manager. Eventuelle tilpassede konfigurasjoner som <a href='https://bluerivermountains.com/no/hendelsessporing'>hendelsessporing</a> eller å legge til andre skriptkoder, gjør du i GTM."
        }
     },
     {
       "@type": "Question",
       "name": "Hva er forskjellen mellom Google Analytics og Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics er biblioteket som samler inn data om besøk på nettstedet ditt. Google Tag Manager derimot er et bibliotek som kjører på nettstedet ditt for å implementere andre biblioteker eller verktøy som Google Analytics. Fordi mange av disse verktøyene har ekstra JavaScript-snutter for å sende data til dem, setter du dem alle opp i GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Hvor legger jeg Google Tag Manager-koden?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Den første delen av koden går så høyt som mulig inn i <head>-seksjonen. Jeg anbefaler å implementere den innenfor <head>, men etter eventuelle <style> eller <script>-koder som er viktige for å rendre siden - fordi vi ikke vil forsinke dem. Den andre delen av GTM-koden er for å aktivere grunnleggende funksjonalitet på nettsteder med JavaScript slått av. Den går så høyt som mulig inn i <body>-elementet. Logikken bak plasseringen av begge kodene er å sikre tidlig innlasting av GTM. Det gjør det mulig for deg å gripe inn og kontrollere deler av sideinnlastingsprosessen så tidlig som mulig."
      }
   },
   {
     "@type": "Question",
     "name": "Inkluderer Google Tag Manager Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Nei, men Google Tag Manager lar deg implementere Google Analytics på sekunder med bare noen få klikk. Det eneste du trenger er din Google Analytics-sporings-ID. Generelt sett trenger du ikke bruke Google Analytics med Google Tag Manager. De er uavhengige av hverandre."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Installer Google Tag Manager: Veiledning for oppsett ${currentYear}`}
        description="Lær hvordan du bruker GTM for å sette opp Google Analytics, hendelsessporing, remarketing-tagger og et datalag, samt beste praksis for sporing av nedlastinger og eksterne lenker."
        lang="no"
        canonical="/no/google-tag-manager-oppsett"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="brukergrensesnittet til Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Veiledning</H>

        <p>Som en <Link to="/no/google-tag-manager-konsulent">Google Tag Manager konsulent</Link>, har jeg satt opp GTM på <b>100+ klientnettsteder</b>. Denne Google Tag Manager veiledningen er der jeg lærer deg prosessen jeg har perfeksjonert gjennom årene, trinn for trinn, med eksempler og videoer for at du skal lære.</p>
        <p>Lenger ned kan du <Link to="/no/google-tag-manager-oppsett">laste ned en GTM oppsett konfigurasjonsfil</Link> med alle følgende beste praksiser for å importere til din container.</p>
        <p>Hvis du ikke kan vente, hopp rett inn i <Link to="/no/google-tag-manager-oppsett">installasjonsveiledningen</Link> eller lær <Link to="/no/google-tag-manager-oppsett">hvordan du setter opp Google Tag Manager</Link>. Men hvis du er en <b>nybegynner</b>, er det viktig å først forstå <em>hvordan</em> du bruker et <Link to="/no/tagg-administrasjon">tag management system</Link> sammen med andre verktøy.</p>
        <p>Så fortsett å lese nedenfor først.</p>
        <H as="h2">Hvordan bruke Google Tag Manager?</H>
        <p>Jeg antar at du allerede vet <Link to="/no/hva-er-google-tag-manager">hva Google Tag Manager er</Link>. Så la oss snakke om hvordan GTM fungerer og hvordan du bruker det.</p>
        <p>Ideelt sett vil du bare ha <b>én</b> tredjepartstag i kildekoden på nettstedet eller webappen din.</p>
        <QuoteBox
          quote={`Den eneste tredjepartstaggen på nettstedet ditt bør være Google Tag Manager kodesnippet.`}
        />
        <p>Alle andre tagger implementeres deretter gjennom selve tag manageren. Andre markedsførings- og sporingskoder er f.eks. Google Analytics (GA), Facebook, Twitter, Linkedin, AdWords, DoubleClick og gud vet hva.</p>
        <p>Hovedårsaken er <Link to="/no/google-tag-manager-fordeler">fordelene med Google Tag Manager</Link>:</p>
        <ul>
          <li><b>enklere og raskere</b> implementering av markedsføringstagger</li>
          <li>skalerbarhet på hver side og på tvers av flere domener</li>
          <li><b>innebygde triggere</b> for skjema innsendinger, rullesporing og videosporing</li>
          <li>administrer brukere med flere GTM-kontoer</li>
          <li>litt <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">raskere lasting av nettstedet</a></li>
        </ul><br />
        <p>På grunn av disse fordelene, bruker allerede <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% av alle nettsteder på internett en tag manager</a>. Og blant dem har Google Tag Manager en markedsandel på <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Så, med mindre du har en god grunn til å ikke legge til en tag i GTM, som en tommelfingerregel, <b>legg alle tagger i GTM containeren</b>.</p>

        <QuoteBox
          quote={`Bruk GTM som et koblingslag mellom nettstedet ditt og tredjepartstagger.`}
        />
        <p>Bruk GTM som et <b>mellomlag</b> mellom nettstedet ditt og tredjepartstjenester. Uten det er ikke nettstedet ditt og tredjepartstagene i direkte forbindelse. Disse tjenestene er hovedsakelig JavaScript-biblioteker for markedsførings- eller sporingsverktøy som implementeres med en tag. Men alle andre tagger gjelder også.</p>
        <p>Det eneste unntaket fra regelen gjelder når du gjør konverteringsoptimalisering med delt-testingsverktøy.</p>
        <p>Fordi under konverteringsoptimalisering vil A/B-tester laste forskjellige varianter av en side. Så besøkende kan se hvordan innholdet gjengis på nytt i et splittsekund.</p>
        <p>For å unngå CSS-flimmer og sikre at varianttester lastes raskt, kan en delt-testings tag også gå direkte inn i kildekoden.</p>
        <p>Nå som vi har dette ut av veien, la oss se på implementeringen.</p>
        <H as="h2">Installer Google Tag Manager på nettstedet ditt</H>
        <p>La oss starte Google Tag Manager veiledningen ved å vise deg hvor du får Google Tag Manager kodesnippet og deretter hvor du installerer det på nettstedet. Du kan logge inn ved å bruke din vanlige Google-konto.</p>
        <ol>
          <li><H as="h3">Opprett en Google Tag Manager konto</H>
            For å installere GTM må du først gå til <a rel="noopener" target="_blank" href="https://tagmanager.google.com/">offisiell nettside</a> og opprette en ny Google Tag Manager konto.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Opprette Google Tag Manager konto"
              caption="Først, opprett en Google Tag Manager konto, og velg et container navn, som navnet på nettstedet ditt og deretter få kodesnippet."
              className="article-img"
            />
          </li>
          <li><H as="h3">Opprett en web-egenskap</H>
            Velg <b>Web</b> egenskapen for å få en kode for et nettsted eller webapp.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM container navn og web egenskap valg"
              className="article-img"
              caption="Det finnes flere typer containere tilgjengelig i en GTM-konto. For nettsteder, velg web. Merk at det finnes andre tag manager container typer for AMP-sider, iOS og Android også."
            />
          </li>
          <li><H as="h3">Implementer Google Tag Manager koden</H><ul>
            Deretter vil du bli vist Google Tag Manager koden for å implementere på nettstedet ditt.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager kodesnippet"
              caption="Dette er Google Tag Manager container taggen. Den har to deler. Instruksjonene for hvordan du implementerer skriptkoden er skrevet over hver del."
              className="article-img"
            />
            <li>Ta <b>første del</b> av <Link to="/no/wiki-analytics/container-merke">container taggen</Link> og legg den så høyt som mulig i <b>head</b> taggen på hver side av nettstedet ditt. Dette sikrer at du kan kjøre tagger tidlig under sideinnlasting.</li>
            <li><b>Andre del</b> er en iframe for å kjøre i nettlesere som har JavaScript deaktivert. Installer taggen så høyt som mulig i <b>body</b> taggen på hver side av nettstedet ditt.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="dataLayer er plassert før TMS taggen i kildekoden"
                caption={`Den første taggen i <head> er et datalag. Ikke bekymre deg hvis du ikke vet ennå hva det er (første pil). Neste er den første delen av GTM-taggen (andre pil). Til slutt er den andre delen av GTM-taggen implementert høyt oppe i <body> elementet. Andre skript kan implementeres i mellom, men et datalag er alltid implementert før GTM-taggen og <noscript> GTM-taggen er alltid sist.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>Dette er den vanlige metoden for å implementere GTM.</p>
        <p>Bruker du et populært innholdsstyringssystem? Hvis ja, kan du også bruke en <b>plugin</b> som tar seg av installasjonen av Google Tag Manager.</p>
        <p>Det sagt:</p>
        <InfoBox
          type="info"
          headline="Hvis ditt CMS også tilbyr en plugin for å installere andre tagger"
          h="false"
          dots="true"
          tweetcopy="Hvis ditt CMS også tilbyr en plugin for å installere andre tagger, bruk ikke enda en plugin for å installere Google Analytics, Facebook eller Google Ads. Bruk heller GTM til å installere de taggene."
        >
          <p>Bruk ikke enda en plugin for å installere Google Analytics, Facebook eller Google Ads.</p>
          <p>Bruk heller <b>GTM til å installere de taggene</b>.</p><br />
          <ol>
            <li>Det vil resultere i raskere sideinnlasting</li>
            <li>Det gir deg flere alternativer for å konfigurere taggen</li>
          </ol>
        </InfoBox>
        <p>GTM brukergrensesnittet mottar også regelmessige oppdateringer med nye funksjoner, så du er nesten alltid bedre tjent med å implementere andre markedsføringstagger direkte med det enn med en annen integrasjon.</p><p>Pluss, gevinstene i lastetid er bra for din <Link to="/no/wiki-analytics/bounce-rate">fluktfrekvens</Link> og hjelper SEO.</p>
        <H as="h3">Bruk en plugin for å implementere GTM</H>
        <p>Nedenfor en liste over de mest vanlige innholdsstyringssystemene og deres plugins for å installere Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>Det er to WordPress plugins for å implementere GTM som jeg vil bruke. <b>Først</b>, er det det klassiske alternativet kalt <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager for WordPress</a>.<br />
          <b>Det andre</b> alternativet er <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site Kit by Google</a>. Det lar deg først og fremst legge til et dashbord til din Wordpress backend som viser informasjon fra din Google Analytics konto og Google Search Console data - noe som er ganske bra. Og det lar deg også installere GTM.</p>
        <H as="h4">Shopify</H>
        <p>For Shopify, er det en <b>gratis</b> plugin for GTM installasjon kreativt kalt <em><a rel="noopener" target="_blank" href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>For Squarespace finnes det ingen GTM-utvidelse eller plugin. Men du kan legge til GTM-taggen manuelt, ved å gå til <b>sidepanel</b> &gt; <b>innstillinger</b> &gt; <b>avansert</b> &gt; <b>kodeinjeksjon</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace navigasjonsmeny for kodeinjeksjon"
          caption={`I Squarespace kan du implementere GTM under Innstillinger > Avansert > Kodeinjeksjon`}
          className="article-img"
        />

        <p>Deretter limer du inn GTM-taggen i skjema feltene slik:</p>


        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Injiser GTM kodesnippets i Squarespace"
          caption={`Sett den første delen av GTM-koden i header-feltet. Den andre delen går inn i footer-feltet.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Gå til hovedmenyen for Wix-nettstedet ditt på venstre sidepanel. Derfra går du til <b>Markedsføring og SEO</b> og klikker deretter på <b>Markedsføringsintegrasjoner</b> lenger nede i sidepanelet.<br />
          Der finner du flere Wix-integrasjoner for Google Analytics, Facebook-pixel og også en Wix-utvidelse for å implementere Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix markedsføringsintegrasjon for Google Tag Manager"
          caption={`I Wix bruker du markedsføringsintegrasjonen for Google Tag Manager.`}
          className="article-img"
        />
        <p>Klikk på koble til og få Google Tag Manager installert.</p>










        <H as="h2">Hvordan sjekke om GTM fungerer?</H>
        <InfoBox
          type="info"
          headline="Når du logger inn på GTM første gang"
          h="false"
          dots="true"
          tweetcopy="Når du logger inn på GTM første gang...Gå til send-knappen og publiser en tom container. Ellers, når du tester om GTM fungerer, vil skriptet returnere en 400 svarfeil og du vil bruke timer på å finne ut hvorfor. 😭"
        >
          <p>Gå til send-knappen og publiser en <b>tom container</b>.</p>
          <p>Ellers, når du tester om GTM fungerer, vil skriptet returnere en <b>400 svarfeil</b> og du vil bruke timer på å finne ut hvorfor. 😭 <br /><br />Det er en klassiker 😉</p>
        </InfoBox>
        <p>Etter at du har implementert GTM-skriptet og <b>publisert en container</b> versjon (viktig), kan du teste om Google Tag Manager fungerer ved å gjøre noen av disse kontrollene:</p>
        <ol>
          <li><H as="h3">Forhåndsvisning og feilsøkingsmodus</H>Logg inn på din GTM-konto og klikk på <b>forhåndsvisning</b>. Åpne deretter en ny fane i nettleseren og besøk nettstedet ditt. GTM-feilsøkingsvinduet skal dukke opp nederst i vinduet hvis GTM fungerer som det skal.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Aktiver GTM feilsøkingsmodus"
              caption={`Aktiver GTM feilsøkingsmodus for å sjekke om GTM fungerer riktig.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Åpne Chrome Developer Tools</b> med et høyreklikk på en hvilken som helst side på nettstedet ditt og velg <em>inspiser</em> (alternativt F12 på Windows og Shift+CTRL+J på Mac).<br />
            Gå deretter til <b>nettverk</b> fanen og <b>last inn nettsiden på nytt samtidig</b> (F5 på Windows og CMD+Shift+R på Mac). Nettverksfanen fylles med alle nettverksforespørsler som er nødvendige for å laste siden.<br />
            I filterfeltet øverst til venstre, skriv <em>gtm.js</em> for å finne forespørselen om JavaScript-koden din og verifisere at den har
            en <b>statuskode på 200</b>.<br /><br />
            La meg vise deg:<br /><br />
            <video

              loading="lazy"
              title={`Sjekk om Google Tag Manager fungerer`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Hvis du ikke har en 200 statuskode, glemte du kanskje å sende inn og publisere en container først i GTM?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installer <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-utvidelsen og start den på nettstedet ditt. Den bør vise en GTM container-ID.<br />

            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag inspector validerer at GTM lastes riktig"
                caption={`Du kan også bruke Chrome-utvidelsen Google Tag Assistant for å sikre at Google Tag Manager fungerer riktig.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Hvordan sette opp Google Tag Manager?</H>
        <p>Når du setter opp Google Tag Manager kan du gjøre mange avanserte konfigurasjoner. Så <b><em>hvordan</em></b> du setter opp GTM, avhenger
          av hvilke andre verktøy du planlegger å bruke i ditt <Link to="/no/tagg-administrasjon">tag management system</Link>.</p>
        <p>Derfor har jeg samlet alle relevante veiledninger som dekker alt du kan ønske å sette opp i din GTM-konto - med
          eksempler. Følg denne Google Tag Manager veiledningen og lag en solid tag management grunnlag for nettstedet ditt.</p>
        <p>Kun veiledningen om implementering av et datalag krever kodingsferdigheter eller potensielt webutviklere.</p>
        <p><b>Merk</b>: I denne Google Tag Manager veiledningen, vil vi bruke GTM ved <b>manuelt</b> å sette opp nye tagger og triggere for hver hendelse. Tilnærmingen er ikke super skalerbar, men den er rask nok og enkel, mens den møter de fleste sporingsambisjoner og fortsatt kan brukes på andre avanserte oppsett.</p>
        <p>Større nettsteder og netthandelsbutikker krever en <b>skalerbar tag management løsning</b>. Derfor implementeres et <Link to="/no/data-lag">datalag</Link> som den sentrale delen for å spore hendelser. Med et slikt oppsett kan du bruke hendelseshåndterere i stedet for å sette opp tagger, triggere og variabler for hver hendelse.</p>
        <ol>
          <li>
            <H as="h3">Sett opp Google Analytics sporing</H>
            <p>Dette er det første steget for alle. Lær i denne veiledningen hvordan du implementerer solid Google Analytics sporing, med Mål, Trakter, og dine egne besøk
              ekskludert fra trafikken. Pluss flere beste praksiser.</p>

            <Link to="/no/google-analytics-oppsett"><CtaPrimary color="purple" arrow="true">Sett opp Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Sett opp hendelsessporing</H>
            <p>Når den grunnleggende sporingsimplementeringen kjører som den skal, vil du også lære å spore brukerengasjement. Hvor ofte, for eksempel, sender en besøkende inn skjemaer og klikker på en send-knapp eller et annet HTML-element? Min <Link to="/no/hendelsessporing">hendelsessporings</Link> veiledning forklarer akkurat det for et <b>knapp klikk</b> og du kan bruke den samme metoden for alle andre klikksporinger.</p>
            <Link to="/no/hendelsessporing"><CtaPrimary color="purple" arrow="true">Sett opp hendelsessporing</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Legg til remarketing-tagger</H>
            <p>Den vanligste bruken av GTM <em>etter</em> installering av GA er å legge til remarketing-tagger på et nettsted. Tross alt utgjør de majoriteten av tredjeparts markedsførings- og sporingskoder som roter til kodebasen vår.<br />
              Derfor implementerer vi dem gjennom vår GTM-konto for å holde kodebasen ren fra markedsførings- og analysetagger mens vi drar nytte av <Link to="/no/google-tag-manager-fordeler">fordelene med Google Tag Manager</Link>.</p>
            <p>La oss lære hvordan du legger til de vanligste remarketing-taggene i digital markedsføring, <b>Facebook-pixel</b>, og <b>Google Ads remarketing tag</b>.</p>
            <H as="h4">Legg til Facebook-pixel</H>
            <ul>
              <li>
                Først trenger du din <b>Facebook-pixel ID</b>. Besøk Facebooks <a rel="noopener" target="_blank" href="https://www.facebook.com/events_manager2/list/pixel/">Events Manager</a> og klikk på det <b>grønne pluss symbolet</b> for å opprette Facebook-pixel. Deretter vil din pixel-ID bli vist på skjermen.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager i Facebook viser pixel ID"
                  caption={`Finn din Facebook-pixel ID i Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Deretter, via Google Tag Manager, <b>opprett en ny tag</b>, kall den for eksempel <em>Facebook - Sidevisning</em> og besøk galleriet for<b>tag maler</b>.
              </li>
              <li>
                Søk etter <em>Facebook</em> og velg Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM's malgalleri viser Facebook pixel tag"
                  caption={`Implementer Facebook pixel fra GTM's tag maler.`}
                  className="article-img"
                />
              </li>
              <li>
                Legg til din <b>Facebook-pixel ID</b> og klikk <b>lagre</b>.
              </li>
              <li>
                Sett taggen til å fyre på <b>alle sider</b>.
                <br /><br />
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer Facebook-pixel i Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Deretter, klikk <b>send</b> øverst til høyre for å publisere taggen.
              </li>
            </ul>
            <H as="h4">Legg til Google Ads remarketing</H>
            <ul>
              <li><b>Først</b>, få din <b>Google Ads konverterings-ID</b> for målgruppen din fra <b>Delt bibliotek &gt; Målgrupper</b>. Brukergrensesnittet endret seg nylig, men se etter <b>tag detaljer</b> eller <b>oppsett tag</b> for å finne informasjonen nedenfor.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Kodebiter for Google konverterings-ID og konverteringsetikett"
                    caption={`Ta din konverterings-ID og konverteringsetikett fra tag-detaljene i målgruppen din.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Gå deretter til <b>tagger</b> seksjonen i GTM og klikk på <b>ny</b> for å legge til vår nye markedsføringstag.</li>
              <li>Gi den et navn som <em>Google Ads - Sidevisning</em>.</li>
              <li>Velg som type variabel <b>Google Ads Remarketing</b>.</li>
              <li>Sett din <b>konverterings-ID</b> og eventuelt <b>konverteringsetikett</b>. La taggen fyre på <b>alle sider</b> og klikk <b>lagre</b>.<br /><br />La meg vise deg i denne videoen:
                <video

                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installer Google Ads sidevisning med GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Klikk <b>send</b> øverst til høyre for å publisere markedsføringstaggen.</li>
            </ul>
            {/* Link -&gt; how to setup conversion google tag manager<br /> */}
            {/* [//]: # (### Implement form tracking)<br /> */}
            {/* [//]: # (Link -&gt; form tracking) */}
            {/* implement scroll tracking */}
            {/* implement video tracking */}
          </li>
          <li>
            <H as="h3">Implementer et datalag</H>
            <p>Du vil implementere et datalag hvis du setter opp tagger regelmessig, og det tar for lang tid og er for arbeidskrevende.</p>
            <p>En annen fordel er at du kan bruke informasjonen fra databasen din til å fyre triggere eller sende det som hendelsesdata. Andre eksterne datakilder kan også integreres. Nettsider som trenger ecommerce sporing faller typisk inn i denne kategorien.</p>
            <p>Min artikkel om datalaget forklarer implementeringen, datalagvariabler og hvordan du konfigurerer tilpasset sporing på en skalerbar måte, som er en typisk brukstilfelle for <b>store nettbutikker</b> som trenger forbedret ecommerce sporing.</p>

            <Link to="/no/data-lag"><CtaPrimary color="purple" arrow="true">Implementer datalag</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Beste praksis for hver GTM-oppsett</H>
        <p>Hver gang jeg setter opp Google Tag Manager, kommer oppsettet med noen konfigurasjoner som jeg legger til hver gang. Disse beste praksisene er anvendelige og nyttige for de fleste bedrifter og bør ikke mangle i noen GTM-veiledning. Se listen nedenfor og velg de som er nyttige for deg.</p>
        <p>Lenger ned kan du <Link to="/no/google-tag-manager-oppsett">laste ned en GTM-oppsett konfigurasjonsfil</Link> med alle disse beste praksisene for å importere til din egen container.</p>
        <H as="h3">Spor utgående lenker</H>
        <p>Å spore utgående lenkeklikk betyr å spore alle klikk på eksterne lenker som leder fra nettstedet ditt til andre nettsteder. Sporing av eksterne lenker er en beste praksis som lar deg vite hvilke nettsteder du sender besøk til og hjelper deg med å verifisere interessen til besøkende.<br />For å implementere ekstern lenkesporing, er det <b>tre trinn</b>:</p>
        <ol>
          <li>
            <H as="h4">Opprett en tilpasset hendelsesvariabel i GTM</H>
            <ul>
              <li>Besøk <b>brukerdefinerte variabler</b> fra GTM-oversikten og klikk på <b>ny &gt; auto-hendelsesvariabel</b>.</li>
              <li>Den skal evaluere URL-en til en klikket lenke og returnere <code>true</code> hvis <code>href</code> attributtet holder en utgående lenke - eller <code>false</code> hvis lenken er intern.</li>
              <li>Gi den nye variabelen et navn som <em>Linkisoutbound</em> og velg <em>variabeltype</em> til å være <b>Element URL</b> og <em>Komponenttype</em> <b>er utgående</b>. Klikk deretter <b>lagre</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Innstillinger for auto-hendelsesvariabel for utgående lenker"
                  caption="Auto-hendelsesvariabelen i Google Tag Manager vil holde en verdi av TRUE når adressen til den klikkede lenken er utgående og peker til en ekstern side."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Opprett en ny trigger for utgående lenker</H>
            <ul>
              <li>I side-navigasjonen under triggere, klikk ny og opprett en ny trigger i GTM.</li>
              <li>Velg <em>trigger type</em> til å være <b>bare lenker</b> og gi den et navn som <em>Hendelse - Utgående lenke</em>.</li>
              <li>Sett triggeren til å <em>fyre på noen lenkeklikk</em> og velg din nye auto-hendelsesvariabel <b>Linkisoutbound</b> til å være lik <code>true</code>:<br />


                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Trigger konfigurasjon for utgående lenkesporing"
                  caption={`Trigger innstillinger for å spore utgående lenker.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Opprett en tag for GA-hendelsen</H>
            <ul>
              <li>Besøk <b>variabler</b> og klikk <b>konfigurer</b> for <em>innebygde variabler</em>. Aktiver deretter <b>klikk-URL</b> variabelen. Lukk igjen og gå til tag-seksjonen.</li>
              <li>Opprett en ny <b>Google Analytics: Universal Analytics</b> tag kalt <em>Hendelse - Utgående lenke</em> med <em>sporings type</em> satt til <b>hendelse</b>.</li>
              <li>For <b>kategori</b> legg bare til <code>Utgående lenkeklikk</code>, <b>handling</b> er <code>klikk</code> og <b>etikett</b> skal referere til vår nyaktiverte GTM-variabel. For å gjøre det, bruker vi doble krøllparenteser <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hendelses <b>verdi</b> kan settes til <code>0</code>, med mindre du kan tildele en nominell verdi for en
                ekstern lenke (f.eks. for tilknyttede lenker).</li>
              <li><b>Non-interaction hit</b> innstillingen skal være satt til <code>false</code>, fordi et utgående klikk
                faktisk er en brukerinteraksjon.</li>
              <li><b>Google Analytics innstillingsvariabel</b> skal være den samme brukerdefinerte variabelen som i din tag for sidevisninger.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Innstillinger for Google Analytics hendelsestag for å spore utgående lenker"
                  caption={`Innstillinger for hendelsestag for å spore utgående lenker.`}
                  className="article-img"
                />

              </li>

              <li>Som siste trinn, bla ned til <em>utløsende</em> seksjon og <b>velg den nylig opprettede triggeren</b> <em>Hendelse - Utgående lenke</em>. Klikk deretter <b>lagre</b> og <b>send</b> container endringene dine fra hovedoversikten.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor klikk på e-postadresser</H>
        <p>Klikk på e-postadresser er en nyttig metrikk som pleier å korrelere med telefonsamtaler eller besøk til en fysisk butikk.<br />For å sette opp Google Analytics sporing for e-postklikk, følg trinnene i veiledningen nedenfor:</p>
        <ol>
          <li>
            <H as="h4">Legg til en ny trigger for klikk på e-postadresser</H>
            <ul>
              <li>Aktiver <b>klikk-URL</b> innebygd variabel. Du kan hoppe over dette trinnet hvis du allerede har gjort dette i
                forrige oppsett. Ellers: Gå til <b>variabler</b> seksjonen og <b>konfigurer</b> <em>innebygde
                  variabler</em> for å legge til <b>klikk-URL</b> variabelen. Lukk panelet etterpå og gå til
                triggere seksjonen.</li>
              <li>Under <b>triggere</b>, klikk <em>ny</em> og opprett en ny trigger kalt f.eks. <em>Hendelse - Mail klikk</em>, sett typen til <b>klikk - bare lenker</b> og la den bare utløse på <em>noen lenkeklikk</em>.</li>
              <li>I betingelsen for disse lenkeklikkene, sett rullegardinmenyene til å være <b>Click URL</b>, deretter <b>inneholder</b> og deretter bare legg til strengen <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Trigger konfigurasjon for å spore klikk på e-postadresser"
                caption={`Trigger konfigurasjon for å spore klikk på e-postadresser.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Sett opp GA-hendelsestaggen</H>
            <ul>
              <li>Opprett en ny tag, velg tagtype <b>Google Analytics: Universal Analytics</b> og gi den navnet <em>Hendelse - E-postlenke</em>. Velg deretter <b>hendelse</b> som <em>sporings type</em>.</li>
              <li><b>Kategorien</b> kan settes til en statisk streng <code>E-post lenkeklikk</code>, <b>handlingen</b> er <code>klikk</code> og <b>etiketten</b> skal referere til <code>{`{{ Click URL }}`}</code>.</li>
              <li><b>Hendelsesverdien</b> kan være <code>0</code> igjen med mindre du kan tildele en meningsfull verdi. Hvis du for eksempel visste at hver 10. e-postkontakt fører til et salg på 100$, kan du tildele 10$ som gjennomsnittlig hendelsesverdi.</li>
              <li><b>Non-interaction hit</b> bør vise <code>false</code>, siden interaksjonen er frivillig og derfor aktiv.</li>
              <li><b>Google Analytics innstillinger</b> variabelen bør være den samme som for din sidevisningstag eller utgående lenketag.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Konfigurasjon av GA e-postklikk hendelse"
                  caption={`Konfigurasjonsinnstillinger for GA-hendelsestag for e-poster.`}
                  className="article-img"
                />

              </li>
              <li>Det siste trinnet er å gå ned til <em>utløsende</em> og <b>velg triggeren vi nettopp opprettet</b> <em>Hendelse - E-post klikk</em>. Deretter, <b>lagre</b> og gå til oversikten for å <b>send</b> endringene dine.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor klikk på telefonnumre</H>
        <p>Sporing av klikk på telefonnumre er hovedsakelig nyttig på mobile enheter. Ved å trykke på en telefonnummerlenke startes en telefonsamtale direkte. På stasjonære datamaskiner initierer museklikk vanligvis ingenting. Men som for sporing av klikk på e-poster, er det en god metrikk for å bekrefte kontaktrater generelt, fordi det er korrelert med andre metoder for å kontakte en bedrift.</p>
        <p>Lær å konfigurere GTM for sporing av telefonnummerklikk, ved å følge trinnene nedenfor.</p>
        <ol>
          <li>
            <H as="h4">Opprett en trigger for klikk på telefonnumre</H>
            <ul>
              <li>Som med de andre veiledningene ovenfor, trenger vi <b>klikk-URL</b> variabelen aktivert fra <em>innebygde variabler</em>.</li>
              <li>Opprett deretter en ny trigger <em>Hendelse - Telefonklikk</em> av typen <b>klikk - bare lenker</b> som bare utløses på <em>noen lenkeklikk</em>.</li>
              <li>Fyll triggerbetingelsen som følger: <b>Click URL</b> - <b>inneholder</b> og deretter strengen <code>tel:</code>. Klikk deretter lagre.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM-innstillinger for en telefonnummertrigger"
                  caption={`Innstillinger for en trigger som utløses ved klikk på telefonnumre.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Opprett en telefonnummerhendelsestagg</H>
            <ul>
              <li>Legg til nok en <b>Universal Analytics tag</b> av typen <b>hendelse</b> og kall den <em>Hendelse - Telefonklikk</em></li>
              <li><b>Kategorien</b> er den statiske strengen <code>Telefonnummer klikk</code>, <b>handlingen</b> er <code>klikk</code> og som <b>etikett</b> bruker vi den innebygde variabelen igjen med doble krøllparenteser, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Hvis du ikke kan tildele en gjennomsnittlig verdi til en lead-samtale, hold den på <code>0</code>. Ellers legger du til et nummer for gjennomsnittsverdien.</li>
              <li><b>Non-interaction hit</b> bør settes til <code>false</code>, fordi dette er en aktiv, frivillig interaksjon. Velg <b>innstillingsvariabelen</b> fra dine brukerdefinerte variabler, som du også bruker for sidevisninger.



                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Konfigurasjon av GA telefonnummer klikk hendelse"
                  caption={`Konfigurasjon av GA telefonnummer klikk hendelse.`}
                  className="article-img"
                />
              </li>
              <li>Nå kobler du din nylig opprettede trigger med denne GA-hendelsestaggen ved å gå ned til utløsningsområdet og velge den nye triggeren <em>Hendelse - Telefonklikk</em>. Det siste trinnet er å <b>lagre</b> og klikke send.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spor nedlastinger</H>
        <p>Sporing av hvor ofte besøkende laster ned materialene dine er en god indikator på engasjement. Slike nedlastinger kan være f.eks. e-bøker, Excel-ark, bilder, videoer eller musikk.</p>
        <p>Sporing av nedlastinger fungerer bra for å skille mellom besøkende grupper som ikke var interessert i sideinnholdet og besøkende som faktisk var interessert og lastet ned det du tilbød.</p>
        <p>Følg denne veiledningen for å lære å sette opp nedlastingssporing:</p>
        <ol>
          <li>
            <H as="h4">Konfigurer en GTM-trigger for nedlastingsklikk</H>
            <ul>
              <li>Opprett en ny trigger kalt <em>Hendelse - Nedlastinger</em>, som <em>trigger type</em> velger du <b>klikk - bare lenker</b> som bare utløses på <b>noen lenkeklikk</b>.</li>
              <li>For betingelsen setter du den innebygde variabelen <b>Click URL</b> til å <b>slutte med</b> strengen <code>.pdf</code> for å spore for eksempel PDF-nedlastinger. Endre utvidelsesstrengen til hvilken som helst filutvidelse du tilbyr for nedlasting.</li>
              <li>Hvis du vil spore <b>flere filutvidelser</b> for nedlasting, kan vi bruke et regulært uttrykk. Endre betingelsen til <b>Click URL</b> og deretter <b>matcher RegEx (ignorer små/store bokstaver)</b> og legg til dette RegEx-strengen <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Føl deg fri til å legge til andre filutvidelser som er relevante for nettstedet ditt og fjern andre som ikke er det. Fullfør ved å klikke <b>lagre</b>.<br />


                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Trigger konfigurasjon for nedlastingssporing med innebygde variabler"
                  caption={`Trigger konfigurasjon for nedlastingssporing med innebygd variabel.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Spor nedlastinger med en Google Analytics hendelse</H>
            <ul>
              <li>Legg til en ny tag av typen Universal Analytics, velg <b>hendelse</b> som sporings-type og kall den for eksempel <em>Hendelse - Nedlastinger</em>.</li>
              <li><b>Kategorien</b> for hendelsen er strengen <code>Nedlasting</code>, <b>handlingen</b> er strengen <code>klikk</code> og <b>etiketten</b> er <code>{`{{ Click URL }}`}</code>.</li>
              <li><b>Non-interaction hit</b> er <code>false</code>, på grunn av at interaksjonen er en aktiv handling.</li>
              <li>Og fra de brukerdefinerte variablene, bruk den samme <b>innstillingsvariabelen</b> som i andre sporings tagger.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Hendelsestagg for nedlastingssporing"
                  caption={`Innstillinger for hendelsestag for nedlastingssporing.`}
                  className="article-img"
                />

              </li>

              <li>Nå legger du til en trigger til denne taggen ved å bla ned og velge <em>Hendelse - Nedlastinger</em>.</li>
              <li>Neste trinn, klikk <b>lagre</b>, gå til oversikten og <b>send</b> endringene.</li>
            </ul>
          </li>
        </ol>
        {/* todo */}
        {/* <H as="h3">Legg til rullesporing</H> */}
        <H as="h2">Veiledning for testing av tagger og triggere</H>
        <p>En Google Tag Manager veiledning ville ikke vært komplett uten en del om feilsøking. For å teste noen av de tidligere hendelseskonfigurasjonene og være sikker på at de fungerer, gjør noen av følgende:</p>
        <ol>
          <li><H as="h3">GTM Forhåndsvisningsmodus</H>La oss først starte forhåndsvisnings- og feilsøkingsmodus. I Google Tag Manager-oversikten, klikk på <b>forhåndsvisning</b> knappen øverst til høyre. Åpne deretter en ny fane <b>i samme nettleser</b> og du vil merke at et GTM-feilsøkingsvindu dukker opp nederst.<br /><br />
            Mens du er i forhåndsvisningsmodus, utfør taggene og triggerne selv. Klikk f.eks. på en ekstern lenke eller et telefonnummer og se om triggeren og taggen vises i GTM-feilsøkingsvinduet sammen med hendelsesdetaljene du la til.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="Google Tag Manager feilsøkingsmodus for hendelser"
              className="article-img"
              caption={`Google Tag Manager feilsøkingsvinduet åpnes automatisk når forhåndsvisningsmodus er aktivert og viser data om tagger, triggere og variabler.`}
            />


          </li>
          <li><H as="h3">Google Analytics sanntidsrapport for hendelser</H>En annen metode er å logge inn på Google Analytics og deretter besøke <b>sanntidsrapporten for hendelser</b>. Hendelsene skal vises noen sekunder etter at triggeren utløses.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="Google Analytics sanntidsrapport for hendelser"
              className="article-img"
              caption={`Du kan verifisere GTM-konfigurasjoner med Google Analytics sanntidsrapport.`}
            />


          </li>
          <li><H as="h3">Chrome utvidelse for feilsøking</H>Installer en Chrome-utvidelse for å feilsøke taggen og triggeren. Jeg anbefaler <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> eller <a
              rel="noopener" target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, men
            det finnes også andre løsninger der ute. Når du har lagt til utvidelsen i Chrome, kan du åpne <b>Chrome Developer
              Tools</b> og det vil være en ny fane tilgjengelig. Den viser deg all informasjon om de utløste taghåndteringsreglene.
            Hvis du deretter utløser triggerne dine i feilsøkingsmodus, vil utvidelsen vise trigger- og hendelsesdata.
            <br />
            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome-utvidelse viser hendelsesdata."
              className="article-img"
              caption={`Trackie Chrome-utvidelse viser hendelsesdata i Chrome Developer Tools.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome-utvidelse viser hendelsesdata for feilsøking"
              className="article-img"
              caption={`Omnibug Chrome-utvidelse viser hendelsesdata for feilsøking i Chrome Developer Tools.`}
            />
          </li>
        </ol>



        <H as="h2">Last ned GTM konfigurasjonsfil</H>
        <p>Siden de ovennevnte konfigurasjonene er universelt nyttige for de fleste Google Tag Manager-implementeringer, opprettet jeg det ovennevnte GTM-oppsettet som en fil for å importere til andre Google Tag Manager-containere.<br />
          Det er en <code>.json</code> fil med innstillingene og navnekonvensjonen vi gikk gjennom. Du kan bare importere containerkoden uten å måtte sette opp noe manuelt.</p>
        <p>Enten <b>bruker du den med en helt ny container</b> for å spare tid på å sette opp taggene selv, eller du kan <b>importere
          dem til din eksisterende container</b> og oppdatere Google Analytics-innstillinger variabelen inkludert sporings-ID-en til din egen ID.</p>
        <p>Du kan laste ned og installere disse konfigurasjonene (hver med tagger, triggere og variabler) for å sette opp Google Tag Manager:</p>
        <ul>
          <li>Spor utgående lenker</li>
          <li>Spor e-postklikk</li>
          <li>Spor telefonnummerklikk</li>
          <li>Spor nedlastinger</li>
        </ul>
        <p className="baseline">Importer ganske enkelt containerinnstillingene og distribuer dem. For demonstrasjonsformål la jeg til en Google Analytics-innstillinger variabel med en Google Analytics sporings-ID på <em>UA-12345678-9</em>.</p>
        <p><b>Vennligst oppdater GA sporingskoden til din egen</b> eller
          alternativt, <b>endre GA-innstillinger variabelen i tagkonfigurasjonen til din egen</b>.</p>
        <p>Last ned GTM-oppsett konfigurasjonen og se nedenfor hvordan du importerer den.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Last ned GTM-oppsett</CtaPrimary></a>
        <H as="h3">Importer innstillinger til din GTM-container</H>
        <p>For å få mest mulig ut av denne GTM-veiledningen, følg trinnene nedenfor og importer innstillingene til din GTM-container:</p>
        <ul>
          <li>gå til <b>admin</b> &gt; <b>importer container</b>.</li>
          <li>velg JSON-filen du nettopp lastet ned.</li>
          <li>velg en <b>nytt arbeidsområde</b> navngitt f.eks. <em>Import Bluerivermountains</em>.</li>
          <li>som <b>importalternativ</b> velg å <b>flette</b> og <b>gi nytt navn</b> til eventuelle motstridende tagger, triggere og variabler.</li>
          <li>klikk <b>bekreft</b> og trykk på <b>send</b> og <b>publiser</b> knappen for å distribuere taggene.<br /><br />La meg vise deg i denne videoen:

            <video

              loading="lazy"
              title={`Veiledningsvideo for å importere en Google Tag Manager Container`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>

          </li>

          <li>Endelig, endre GA sporings-ID i Google Analytics innstillinger variabelen til din egen sporings-ID eller oppdater innstillinger variabelen i
            taggene til din egen innstillinger variabel.</li>
        </ul>
        <H as="h2">Merknad om personopplysninger</H>
        <p>Vi må være bevisste på informasjonen vi sporer. Data er ikke bare data, fordi land har forskrifter om databeskyttelse som påvirker typen informasjon vi kan samle inn under sporing.</p>

        <p>Det finnes også vilkår fra Googles side, som forbyr sporing av personopplysninger og sending av data til deres servere.</p>

        <p>Med det sagt:</p>
        <p>Generelt sett er <b>e-poster eller telefonnumre personlig identifiserbar informasjon (PII)</b> og vi har ikke lov til å
          sende det til vår Google Analytics-konto, fordi det er <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">mot deres tjenestevilkår</a>.</p>
        <p>Men telefonnumrene på vårt nettsted eller våre egne bedrifts-e-postadresser er neppe private, fordi det ikke er brukernes
          data, men våre egne og offentlig tilgjengelige på nettstedet.<br />
          Ikke desto mindre, hvis Google Analytics <em>noen gang</em> sjekket databasen sin og fant de dataene, kunne de ikke vite
          at det faktisk ikke er PII-data.<br />
          Derfor anbefaler jeg å ikke ta noen risiko og <b>obfuskere alle e-postadresser og telefonnumre sendt til
            Google Analytics-kontoen</b>.</p>
        <p>Simo Ahava har gjort en flott jobb og skrevet et <a rel="noopener" target="_blank"
          href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">egendefinert skript for å fjerne PII
          fra Google Analytics-hits</a> og jeg anbefaler å implementere dette sammen med de ovennevnte konfigurasjonene.<br />
          Det er ikke et must, men ved å implementere det unngår du enhver potensiell forvirring om du holder PII-data eller ikke.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Trenger jeg Google Tag Manager?</H>
        <p>Ja, fordi nettstedet ditt sannsynligvis ønsker å kjøre Google Analytics og andre tredjeparts skriptkoder. Å sette opp alt
          det er mye enklere og raskere med Google Tag Manager. I tillegg <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">laster
            nettstedet ditt litt raskere</a> også.</p>
        <H as="h3">Når bør jeg bruke Google Tag Manager?</H>
        <p>Allerede hvis du bare vil kjøre Google Analytics bør du bruke Google Tag Manager. Å sette opp <Link to="/no/hendelsessporing">hendelsessporing</Link>, kryss-domenesporing eller skjemasporing er vanlige neste trinn, men mye <b>raskere</b> og <b>enklere</b> med GTM enn uten. Det finnes innebygde triggere for rullesporing og videosporing også, pluss mange veiledninger på nettet som forklarer hvordan du <Link to="/no/google-analytics-oppsett">setter opp Google Analytics</Link> med det.</p>
        <H as="h3">Hvordan bruker jeg Google Tag Manager med Google Analytics?</H>
        <p>Logg inn på <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> med din Google-konto og få din Google Analytics sporingskode inkludert sporings-ID. Nå, ikke legg til Google Analytics-taggen i kildekoden på nettstedet ditt. Den eneste hardkodede taggen bør være Google Tag Manager-taggen. Så gå til <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> for å få GTM kodesnippet og i stedet implementere det på hver side av nettstedet ditt. Til slutt implementerer du GA-koden gjennom en innebygd tag, legger til din sporings-ID og fortsetter til <Link to="/no/google-analytics-oppsett">sette opp Google Analytics</Link> <b>gjennom Google Tag Manager</b>.
          For å legge til markedsføringstagger eller konfigurere tilpassede tagger, bruker du alltid GTM fremover.</p>
        <H as="h3">Hva er forskjellen mellom Google Analytics og Google Tag Manager?</H>
        <p>Google Analytics er biblioteket for å samle data om besøk og engasjement på nettstedet ditt. Google Tag Manager derimot er et bibliotek som kjører på nettstedet ditt for å implementere andre biblioteker eller <em>sporingsverktøy</em> som Google Analytics. Fordi mange markedsførings- og analyseverktøy har ekstra JavaScript-snutter, bruker du GTM brukergrensesnitt for å sette dem alle opp.</p>
        <H as="h3">Hvor legger jeg Google Tag Manager-koden?</H>
        <p><b>Første</b> del av koden går <em>så høyt som mulig</em> inn i <code>&lt;head&gt;</code> seksjonen. Jeg anbefaler å implementere den innenfor <code>&lt;head&gt;</code> men etter eventuelle <code>&lt;style&gt;</code> eller <code>&lt;script&gt;</code> tagger som er viktige for å gjengi siden - fordi vi ikke vil forsinke dem.<br />
          <b>Andre</b> del er for å aktivere grunnleggende funksjonalitet i nettlesere med JavaScript slått av. Den går <em>så høyt som mulig</em> inn i <code>&lt;body&gt;</code> elementet.<br />
          Logikken bak plasseringen av begge taggene er å sikre tidlig lasting av GTM. Det lar deg fyre tilpassede tagger så tidlig som mulig under sideinnlasting.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager-kode"
          className="article-img"
          caption={`GTM-taggen har to deler. Den første plasseres høyt inn i head-taggen og den andre rett etter åpning av body-taggen (se nedenfor).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="plassering av datalag før tag management-taggen i kildekoden"
          className="article-img"
          caption={`Den generelle rekkefølgen skal alltid være 1. datalagobjekt 2. Google Tag Manager, begge i <head> og 3. den andre GTM-koden høyt i <body> taggen.`}
        />

        <H as="h3">Inkluderer Google Tag Manager Google Analytics?</H>
        <p>Nei, men Google Tag Manager lar deg implementere Google Analytics på sekunder med bare noen få klikk. Det eneste
          du trenger er din <Link to="/no/google-analytics-oppsett">Google Analytics sporings-ID</Link>. Generelt sett trenger du ikke bruke Google Analytics med Google Tag Manager.
          De er <b>uavhengige</b> av hverandre.</p>
        <H as="h3">Hvordan får jeg tilgang til Google Tag Manager?</H>
        <p>Besøk <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> og logg inn med din Google-konto for å få tilgang til Google Tag Manager. For å begynne å bruke GTM, opprett en ny konto og velg web-egenskap som målplattform. Ta deretter kodesnippet og installer det på hver side av nettstedet ditt.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;

